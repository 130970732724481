import React, { useEffect, useState } from "react";
import HostpotUserService from "data/Handler/HostpotVoucher/HostpotUserService";
import { verifySuperAdmin } from "data/Variable/Utils";
import { CatchError } from "data/Handler/Exceptions/exceptions";
import { useLocation } from "react-router-dom";
import { PriceFormat } from "data/Variable/Common";

function PrintVoucher(props) {
  const hostpotUserService = new HostpotUserService()
  const [load, setDataLoad] = useState(true)
  const [Table, setDataTable] = useState([])
  const { id: userId } = verifySuperAdmin()
  const location = useLocation()
  const { state } = location
  const { mitraId, resellerUserId, voucherId } = state


  const getAll =  async(mitra = '', reseller = '', voucherId = '') => {
    try {
        const trash = false
        setDataLoad(true)
        // console.log({ trash, mitra, reseller })
        if (!voucherId) {
            const len = 'ALL'
            const { data } =  await hostpotUserService.getAll(len, trash, mitra, reseller)
            const result = data

            setDataTable(result)
            setDataLoad(false)
            return result
        }

        const data =  await hostpotUserService.get(voucherId, mitraId)
        const result = []
        result.push(data)
        setDataTable(result)
        setDataLoad(false)
        return result
    } catch (error) {
      setDataLoad(false)
      return await CatchError(error, props)
    }
  }

  useEffect(() => {
    getAll(mitraId, resellerUserId || userId, voucherId)
  }, [])

  function voucherTabs(data) {
    return (
       <div className="voucher-card" key={data.id}>
      <div className="voucher-header">
        <span className="logo">INTERNET voucher</span>
        <span className="wifi-logo">WiFi</span>
      </div>
      <div className="voucher-content">
        <div className="voucher-item">
          <span className="label">PROFILE</span>
          <span className="value">{data.profile}</span>
        </div>
        <div className="voucher-item">
          <span className="label">USER</span>
          <span className="value">{data.username}</span>
        </div>
        <div className="voucher-item">
          <span className="label">PASSWORD</span>
          <span className="value">{data.password}</span>
        </div>
        <div className="voucher-item">
          <span className="label">PRICE</span>
          <span className="value">{PriceFormat(data.paidAmount, 'idr')}</span>
        </div>
      </div>
      <div className="voucher-footer">
        {/* <div className="qr-code">
          <img src={data.qrCode} alt="QR Code" />
        </div> */}
        <div className="terms">
          <p>
            Please read carefully about terms of use shown in the service login page before activating this voucher.
            Generated by Administrator ({new Date(data.creation).toDateString()}).
          </p>
        </div>
      </div>
    </div>
    );
  }

  useEffect(() => {
    if (!load) {
      window.print();

      // Optionally return to the previous page after printing
      window.onafterprint = () => {
        window.history.back();
      };
    }
  }, [load]);

  return (
      <main className="h-full">
        {/* Main Content */}
        <div className="mainCard">
          <div className="border w-full border-gray-200 bg-white py-4 px-6 rounded-md">
              <div>
                {Table.map((voucher) => voucherTabs(voucher))} {/* Render each voucher tab */}
              </div>
              <p className="pagebreak">&nbsp;</p>
          </div>
        </div>
      </main>
  )
}

export default PrintVoucher;
