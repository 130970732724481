import React from "react";
import { Route, Routes } from "react-router-dom";

import Dashboard from "./pages/Dashboard";
// import Table from "./pages/Table";
import AuthLayout from "./components/Layout/AuthLayout";
import GuestLayout from "./components/Layout/GuestLayout";
import Login from "./pages/auth/Login";
// import Blank from "./pages/Blank";
import NotFound from "./pages/ErrorPages/NotFound";
// import Form from "./pages/Form";
// import RegisterIndex from "./pages/auth/Register";
import UserList from "./pages/UserManager/UserList";
import UserForm from "./pages/UserManager/UserForm";
import UserDetail from "./pages/UserManager/UserDetail";
import HostpotProfileList from "pages/HostpotVoucher/HostpotProfile/HostpotProfileList";
import HostpotUserList from "pages/HostpotVoucher/HostpotUser/HostpotUserList";
import HostpotProfileForm from "pages/HostpotVoucher/HostpotProfile/HostpotProfileForm";
import HostpotUserForm from "pages/HostpotVoucher/HostpotUser/HostpotUserForm";
import HostpotProfileDetail from "pages/HostpotVoucher/HostpotProfile/HostpotProfileDetail";
import HostpotUserDetail from "pages/HostpotVoucher/HostpotUser/HostpotUserDetail";
import RouterList from "pages/RouterManager/RouterList";
import RouterForm from "pages/RouterManager/RouterForm";
import RouterDetail from "pages/RouterManager/RouterDetail";
import ResellerList from "pages/ResellerManager/ResellerList";
import ResellerForm from "pages/ResellerManager/ResellerForm";
import ResellerDetail from "pages/ResellerManager/ResellerDetail";
import HostpotUserGenerate from "pages/HostpotVoucher/HostpotUser/HostpotUserGenerate";
import PppoeUserList from "pages/PPPOE/PppoeUser/PppoeUserList";
import PppoeProfileList from "pages/PPPOE/PppoeProfile/PppoeProfileList";
import PppoeProfileForm from "pages/PPPOE/PppoeProfile/PppoeProfileForm";
import PppoeProfileDetail from "pages/PPPOE/PppoeProfile/PppoeProfileDetail";
import PppoeUserForm from "pages/PPPOE/PppoeUser/PppoeUserForm";
import PppoeUserDetail from "pages/PPPOE/PppoeUser/PppoeUserDetail";
import PppoeInvoiceList from "pages/PPPOE/PppoeInvoice/PppoeInvoiceList";
import PppoeInvoiceUpdate from "pages/PPPOE/PppoeInvoice/PppoeInvoiceUpdate";
import PppoeGenerateInvoice from "pages/PPPOE/PppoeInvoice/PppoeGenerateInvoice";
// import PppoeInvoiceEmailPage from "pages/PPPOE/PpppoeSubscriptionHistory/PppoeInvoiceEmailPage";
// import PppoeInvoiceMailBroadcast from "pages/PPPOE/PppoeInvoice/PppoeInvoiceMailBroadcast";
import PppoeInvoiceExport from "pages/PPPOE/PppoeInvoice/PppoeInvoiceExport";
import UserProfile from "pages/UserManager/UserProfile";
import PrintVoucher from "pages/HostpotVoucher/print/PrintVoucher";

function App() {
  return (
    <Routes>
        {/* Auth */}
        <Route path="/" element={<AuthLayout />}>
          <Route path="/" element={<Dashboard />}></Route>
           {/* Hostpot Profile */}
          <Route path="/hostpot/profile" element={<HostpotProfileList />}></Route>
          <Route path="/hostpot/profile/form" element={<HostpotProfileForm />}></Route>
          <Route path="/hostpot/profile/detail" element={<HostpotProfileDetail />}></Route>
          {/* Voucher */}
          <Route path="/hostpot/user" element={<HostpotUserList />}></Route>
          <Route path="/hostpot/user/form" element={<HostpotUserForm />}></Route>
          <Route path="/hostpot/user/generate" element={<HostpotUserGenerate />}></Route>
          <Route path="/hostpot/user/detail" element={<HostpotUserDetail />}></Route>
          {/* PPPOE Profile */}
          <Route path="/pppoe/profile" element={<PppoeProfileList />}></Route>
          <Route path="/pppoe/profile/form" element={<PppoeProfileForm />}></Route>
          <Route path="/pppoe/profile/detail" element={<PppoeProfileDetail />}></Route>
          {/* PPPOE User */}
          <Route path="/pppoe/user" element={<PppoeUserList />}></Route>
          <Route path="/pppoe/user/form" element={<PppoeUserForm />}></Route>
          <Route path="/pppoe/user/detail" element={<PppoeUserDetail />}></Route>
          {/* Subscription and Invoice */}
          <Route path="/pppoe/invoice" element={<PppoeInvoiceList />}></Route>
          <Route path="/pppoe/invoice/detail" element={<PppoeInvoiceUpdate />}></Route>
          <Route path="/pppoe/invoice/generate" element={<PppoeGenerateInvoice />}></Route>
          {/* <Route path="/pppoe/invoice/notification" element={<PppoeInvoiceEmailPage />}></Route> */}
          {/* <Route path="/pppoe/invoice/broadcast" element={<PppoeInvoiceMailBroadcast />}></Route> */}
          <Route path="/pppoe/invoice/export" element={<PppoeInvoiceExport />}></Route>
          {/*Router NAS */}
          <Route path="/router" element={<RouterList />}></Route>
          <Route path="/router/form" element={<RouterForm />}></Route>
          <Route path="/router/detail" element={<RouterDetail />}></Route>
          {/* Reseller */}
          <Route path="/reseller" element={<ResellerList />}></Route>
          <Route path="/reseller/form" element={<ResellerForm />}></Route>
          <Route path="/reseller/detail" element={<ResellerDetail />}></Route>
          {/* Template */}
          {/* <Route path="/table" element={<Table />}></Route>
          <Route path="/blank" element={<Blank />}></Route>
          <Route path="/form" element={<Form />}></Route>
          <Route path="/profile" element={<Blank />}></Route> */}
          <Route path="*" element={<NotFound />}></Route>
      </Route>
      <Route path="/auth" element={<GuestLayout />}>
        <Route path="/auth/login" element={<Login />}></Route>
        {/* <Route path="/auth/register" element={<RegisterIndex />}></Route> */}
      </Route>
      <Route path="/user" element={<AuthLayout />}>
        <Route path="/user" element={<UserList />}></Route>
        <Route path="/user/form" element={<UserForm />}></Route>
        <Route path="/user/detail" element={<UserDetail />}></Route>
        <Route path="/user/profile" element={<UserProfile />}></Route>
      </Route>
      <Route path="/hostpot/voucher/print" element={<PrintVoucher />}></Route>
    </Routes>
  );
}

export default App;
