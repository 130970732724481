import React, { useEffect, useState } from "react";
import Navbar from "./../../../components/Navbar/Index";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import DefaultInput from "../../../components/Input/DefaultInput";
import UserService from "data/Handler/UserService";
// import { getHostpotUserData } from "data/Variable/HostpotVoucher/HostpotUser";
import HostpotUserService from "data/Handler/HostpotVoucher/HostpotUserService";
import HostpotProfileService from "data/Handler/HostpotVoucher/HostpotProfileService";
import { verifySuperAdmin } from "data/Variable/Utils";
import SelectBasic from "components/Input/SelectBasic";
import { calculatePriceDiscount } from "data/Variable/Utils";
import { payloadHostpotUserUpdate } from "data/Variable/HostpotVoucher/HostpotUser";
import swal from "sweetalert";
import { CatchError } from "data/Handler/Exceptions/exceptions";
import { addHostpotUserList } from "data/Variable/HostpotVoucher/HostpotUser";
import Checklist from "components/Button/Checklist";
import { PriceFormat } from "data/Variable/Common";
import { DateTimestr } from "data/Variable/Utils";
import PrimaryIconButton from "components/Button/PrimaryIconButton";
import LoadingScreen from "components/Loader/LoadingScreen";
// import { RandomTextGenerator } from "data/Variable/Utils";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { GlobalHeader } from "components/Datatables/GlobalHeader";

function HostpotUserDetail(props) {
  const [sidebarToggle] = useOutletContext();
  const navigate = useNavigate()
  const location = useLocation()
  const { state } = location
  const hostpotUser = state // || getHostpotUserData()
  const hostpotUserService =  new HostpotUserService()
  const hostpotProfileService = new HostpotProfileService()
  const { mitraSuper, id: userId } = verifySuperAdmin()
  const userService = new UserService()
  const [loading, setLoading] = useState(false)
  const [hostpotProfileOption, setHostpotProfileOption] = useState([])
  const [mitra, setMitra] = useState(hostpotUser.mitra)
  const [username, setUsername] = useState(hostpotUser.username)
  const [password, setPassword] = useState(hostpotUser.password)
  const [discount, setDiscount] = useState(hostpotUser.discount)
  const [isPaid, setIsPaid] = useState(hostpotUser.isPaid)
  const [paidAmount, setPaidAmount] = useState(hostpotUser.paidAmount)
  const [description, setDescription ] = useState(hostpotUser.description)
  const [resellerOption, setResellerOption] = useState([])
  const [hostpotProfileId, setHostpotProfileId] = useState(hostpotProfileOption.find((data) => data.value === hostpotUser.profileId))
  const [reseller, setReseller] = useState(resellerOption.find((data) => data.userId === userId))
  const [router, setRouter] = useState(hostpotUser.routerName)
  const {id, creation, modified,
    createdBy, modifiedBy, mitraId, routerId, resellerId } = hostpotUser

  async function GetReseller(mitra) {
    const resellerList = await userService.getUserSelect(mitra)
    const resellerData = resellerList.data

    setResellerOption(resellerData)

    const defaultReseller = resellerData?.find((data) => data.userId === resellerId)
    const finalSelection = !defaultReseller ? resellerData[0] : defaultReseller
    setReseller(finalSelection)
    return finalSelection?.userId
  }

  async function GetHostpotProfile(routerId) {
    const hostpotProfileList = await hostpotProfileService.getProfileSelect(routerId)
    const hostpotProfileData = hostpotProfileList.data

    setHostpotProfileOption(hostpotProfileData)
    setHostpotProfileId(hostpotProfileData?.find((data) => data.value === hostpotUser.profileId))
    return hostpotProfileData
  }

  useEffect(() => {
    GetHostpotProfile(routerId)
    GetReseller(mitraId)
  }, [])

  const getAll =  async(trash = false) => {
    const len = 'ALL'
    const result =  await hostpotUserService.getAll(len, trash)
    addHostpotUserList(result.data)
  }

  const update = async() => {
    const selected = await swal("Update", "Perubahan Data Tidak Dapat di Batalkan", "info", {
          buttons: {
          update: {text: "Update", value: 'up' },
          cancel: 'Batal'
        }
      }
    )

    switch (selected) {
        case 'up':
          try {
            setLoading(true)
            const payload = payloadHostpotUserUpdate( hostpotProfileId.value, username, password, discount, isPaid, paidAmount, description )

            const result = await hostpotUserService.put(id, payload)
            await getAll()
            await swal(result.status, result.message, 'success')
            setLoading(false)
            return navigate('/hostpot/user')
          } catch (error) {
            setLoading(false)
            return await CatchError(error, props)
          }
        default:
          break;
      }
  }

  const child = (
    <>
      {/* Main Content */}
        <div className="mainCard">
          <div className="border w-full border-gray-200 bg-white py-4 px-6 rounded-md">
            {/* <form> */}
            <GlobalHeader
                header = {'Detail Voucher'}
              />
                  <DefaultInput
                    key={"1"}
                    label = {'Mitra'}
                    disabled
                    value = {mitra}
                    onChange = {(e) => setMitra(e.target.value)}
                    type
                  />
                  <DefaultInput
                    key={"2"}
                    label = {'Lokasi Router'}
                    value = {router}
                    onChange = {(e) => setRouter(e.target.value)}
                    type
                  />
                  <SelectBasic
                    key={"3"}
                    label = {'Hostpot Profile'}
                    value = {hostpotProfileId}
                    onChange = {(e) => {
                      setHostpotProfileId(e)
                    }}
                    option = {hostpotProfileOption}
                  />
                  <SelectBasic
                    key={"4"}
                    label = {"Reseller"}
                    value = {reseller}
                    option = {resellerOption}
                    isDisabled = {!mitraSuper}
                    onChange = {async (e) => {
                      setReseller(e)
                    }}
                  />
                  <DefaultInput
                    key={"5"}
                    label = {'Username'}
                    value = {username}
                    onChange = {(e) => setUsername(e.target.value)}
                    type
                  />
                  <DefaultInput
                    key={"6"}
                    label = {'Password'}
                    value = {password}
                    onChange = {(e) => setPassword(e.target.value)}
                    type
                  />
                  <DefaultInput
                    key={"7"}
                    label = {'Discount'}
                    value = {discount}
                    onChange = {(e) => {
                      if (e.target.value >= 0 && e.target.value <= 100) {
                        setDiscount(e.target.value)
                        setPaidAmount(calculatePriceDiscount(hostpotProfileId.price, e.target.value))
                      }
                    }}
                    type = {'number'}
                  />
                  <Checklist
                    key={"8"}
                    text = {"Lunas"}
                    value = {isPaid}
                    checked = {isPaid}
                    onChange = {(e) => {
                      setIsPaid(e.target.checked)
                      if (e.target.checked === true && !!hostpotProfileId) {
                        setPaidAmount(calculatePriceDiscount(hostpotProfileId.price, discount))
                      } else {
                        setPaidAmount(0)
                      }
                    }}
                  />
                  <DefaultInput
                    key={"9"}
                    label = {'Nilai Bayar'}
                    value = {paidAmount}
                    onChange = {(e) => setPaidAmount(e.target.value)}
                    disabled = {!isPaid}
                    type = {'number'}
                  />
                  <DefaultInput
                    key={"10"}
                    label = {'Price Display'}
                    value = {PriceFormat(paidAmount, 'idr')}
                  />
                  <DefaultInput
                    key={"11"}
                    label = {'Deskripsi'}
                    value = {description}
                    onChange = {(e) => setDescription(e.target.value)}
                    type = {'textarea'}
                  />
                  <DefaultInput
                    key={"12"}
                    label = {'Creation'}
                    disabled
                    value = {DateTimestr(creation)}
                    onChange = {() => {}}
                  />
                  <DefaultInput
                    key={"13"}
                    label = {'Modified'}
                    disabled
                    value = {DateTimestr(modified)}
                    onChange = {() => {}}
                  />
                  <DefaultInput
                    key={"14"}
                    label = {'CreatedBy'}
                    disabled
                    value = {createdBy}
                    onChange = {() => {}}
                  />
                  <DefaultInput
                    key={"15"}
                    label = {'ModifiedBy'}
                    disabled
                    value = {modifiedBy}
                    onChange = {() => {}}
                  />
              <div className="mt-6 flex flex-row gap-4">
                <PrimaryIconButton
                  title="Update"
                  icon = {faRefresh}
                  onClick = {update}
                />
              </div>
            {/* </form> */}
          </div>
        </div>
    </>
  )
  return (
    <>
      <main className="h-full">
        <Navbar toggle={sidebarToggle} />
        <LoadingScreen
          // key = {RandomTextGenerator()}
          children={child}
          loading = {loading}
          text={"Saving..."}
        />
      </main>
    </>
  );
}

export default HostpotUserDetail;
